<template>
  <v-container fluid>
    <div class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small to="/colaboradores/nuevo">
        <v-icon left>mdi-account-plus</v-icon>Crear cuenta
      </v-btn>
      <v-btn class="ma-1" color="primary" small @click="getCuentas">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
    </div>
    <v-card>
      <v-data-table
        :headers="cuentasHeader"
        :items="usuarios"
        :loading="loading"
        :single-select="false"
        item-key="idUsuario"
        checkbox-color="secondary"
        :calculate-widths="true"
        class="fixed-checkbox"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [25, 50, -1],
				}"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="usuarios"
            v-model="inlineFilters"
          />
        </template>

				<template v-slot:item.nombre="{item}">
					<div class="d-flex align-center">
						<v-avatar class="mr-2" size="10" :color="item.userColor" />
						<h4 class="font-weight-bold"> {{ item.nombre }} </h4>
					</div>
				</template>

				<template v-slot:item.disabled="{item}">
					<v-icon color="error" v-if="item.disabled == 1" >mdi-account-minus</v-icon>
					<v-icon color="success" v-else >mdi-account-plus</v-icon>
				</template>

				<template v-slot:item.logged.fechaLogin="{item}">
					<v-chip outlined v-if="item.logged.fechaLogin" >
						{{ parseDate(item.logged.fechaLogin, false, true, true) }}
					</v-chip>
					<span v-else class="text--disabled" >
						Nunca
					</span>
				</template>

        <template v-slot:item.acciones="{ item }">
          <v-card-actions class="pa-0">

            <v-btn
              class="rounded-pill"
              outlined
              small
              color="primary"
              :to="`/colaboradores/${item.idUsuario}`"
            >
              <v-icon left small>mdi-pencil</v-icon>
              Editar
            </v-btn>

            <v-btn
              class="rounded-pill"
              outlined
              small
              :color="!!Number(item.disabled) ? 'success' : 'error'"
              @click.stop="disable(item.idUsuario)"
            >
              <v-icon left small>
								{{ !!Number(item.disabled) ? 'mdi-account-plus' : 'mdi-account-minus' }}
							</v-icon>
              {{ !!Number(item.disabled) ? "Reactivar" : "Desactivar" }}
            </v-btn>

          </v-card-actions>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>

<script>
import { perColumnFilter, parseDate } from "@/utils/index.js";
import pallete from "@/utils/colorPallete.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
	},
	data() {
		return {
			inlineFilters: {},
			loading: true,
			cuentasHeader: [
				{ text: 'Nombre', value: 'nombre', cellClass: "pa-2 text-no-wrap font-weight-bold" },
				{ text: 'Acciones', value: 'acciones', sortable: false, filterable: false },
				{ text: 'Desactivado', value: 'disabled', dataType: 'bool', },
				{ text: 'Ultimo login', value: 'logged.fechaLogin', dataType: 'date', },
				{ text: 'Email', value: 'email' },
				{ text: 'Instaladora', value: 'instaladora', dataType: 'select' },
				{ text: 'Responsable', value: 'responsable.nombre', dataType: 'select' },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
			usuarios: []
		}
	},
	mounted() {
		this.getCuentas();
	},
	methods: {
		parseDate,
		async getCuentas() {
			this.loading = true;
			const { data: usuarios } = await axios(`${process.env.VUE_APP_API_URL}/colaboradores`)
			this.usuarios = usuarios.map(u => {
				u.userColor = pallete[ ((u.idUsuario % pallete.length) + pallete.length) % pallete.length ];
        if (u.logged == null || u.logged == undefined) {
          u.logged = {}
        }
				u.instaladora = u.instaladora?.nombreInstaladora
				return u
			})
			this.loading = false;
		},
		async disable(idUsuario) {
			this.loading = true;
			await axios({url: `${process.env.VUE_APP_API_URL}/colaboradores/disable`, method: 'POST', data: {
				idUsuario
			}})
			this.getCuentas();
		}
	}
}
</script>
